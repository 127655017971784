// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
import './src/fonts/typography.css'


export const onRouteUpdate = () => {
    const scroller = document.querySelector('#container-scroller')
    scroller.scrollTo(0, 0)
    const slides = Array.from(document.querySelectorAll('.slider-section'))
    if(slides.length > 0){
        slides.forEach(slide => slide.classList.remove('is-active'))
    }
}

