import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./globals/Header";
import Footer from "./globals/Footer";
import { Helmet } from "react-helmet";
import { globalHistory } from "@reach/router";
import "../sass/main.scss";
import OffersBanner from "./globals/OffersBanner";

const Layout = (props) => {
  const pageContext = props.pageContext;
  const path = props.path;
  const isHomePage = props.isHomePage;
  const children = props.children;
  const data = props.data;
  let isContact = true;
  let isHome = true;
  let is404;

  if (typeof window !== "undefined") {
    isContact =
      window.location.pathname === "/contact" ||
      window.location.pathname === "/contact/" ||
      window.location.pathname === "/ecommerce-form/" ||
      window.location.pathname === "/ecommerce-form";
    isHome = window.location.pathname === "/";

    if (isContact) {
      console.log("this is the contact");
    }
    if (isHome) {
      //console.log('this is the Home')
    }
    if (props.location.key === "initial") {
      is404 = true;
    }

    if (
      window.location.pathname.includes("/services/") ||
      window.location.pathname.includes("/insights/")
    ) {
      document.querySelector("body").setAttribute("data-bg-color", "bg-white");
    }

    if (window.location.pathname.includes("/services/")) {
      document.querySelector("body").setAttribute("data-class", "");
    }

    // const slashes = (window.location.pathname.match(/\//g) || []).length;
    const caseStudiesRegex = new RegExp("/case-studies/[A-Za-z-]+");
    if (window.location.pathname.match(caseStudiesRegex)) {
      document.querySelector("body").setAttribute("data-bg-color", "bg-black");
    }

    if (window.location.pathname === "/") {
      document.querySelector("body").setAttribute("data-bg-color", "col-1");
      document.querySelector("body").setAttribute("data-class", "");
    }
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        const hovers = document.querySelectorAll(".hover.js_hover");
        if (hovers.length > 0) {
          hovers.forEach((hover) => hover.classList.remove("js_hover"));
        }
      }
    });
  }, []);

  const calculateWindowHeight = () => {
    let vh = document.documentElement.clientHeight * 0.01;
    // console.log(window.innerHeight, document.documentElement.clientHeight)
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const scroller = document.querySelector("#container-scroller");
    scroller.style.minHeight = document.documentElement.clientHeight + "px";
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      setTimeout(
        function () {
          calculateWindowHeight();
        }.bind(this),
        10
      );
      window.addEventListener("resize", calculateWindowHeight, { passive: true });
      window.addEventListener("wheel", function (e) {
        const yDelta = e.deltaY;
        let y = 0;
      });
    }
    return () => {
      window.removeEventListener("resize", calculateWindowHeight, { passive: true });
    };
  }, []);

  const {
    wp: {
      generalSettings: { title },
      myOptionsPage: {
        acf_options: {
          phoneFormatted,
          cookiePopupText,
          globalStructuredMarkup,
          localBusinessImage,
          organizationImage,
          productImage,
          showOffersBanner,
          offersBannerText,
          offersCtaButton,
        },
      },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
        myOptionsPage {
          acf_options {
            phoneFormatted
            cookiePopupText
            globalStructuredMarkup {
              markup
            }
            localBusinessImage {
              localFile {
                publicURL
              }
            }
            organizationImage {
              localFile {
                publicURL
              }
            }
            productImage {
              localFile {
                publicURL
              }
            }
            showOffersBanner
            offersBannerText
            offersCtaButton {
              buttonText
              buttonUrl
            }
          }
        }
      }
    }
  `);

  console.log(showOffersBanner, offersBannerText, offersCtaButton);

  return (
    <div>
      <Header
        phone={phoneFormatted}
        title={title}
        cookieText={cookiePopupText}
        is404={is404}
        showOffersBanner={showOffersBanner}
      />
      {showOffersBanner && <OffersBanner text={offersBannerText} cta={offersCtaButton} />}
      <Helmet>
        {globalStructuredMarkup.map((markup) => {
          const theMarkup = markup.markup;
          let finalMarkup;

          if (theMarkup.includes("__REPLACE_WITH_ORGANIZATION_IMAGE__")) {
            finalMarkup = theMarkup.replaceAll(
              "__REPLACE_WITH_ORGANIZATION_IMAGE__",
              process.env.GATSBY_SITE_URL + organizationImage.localFile.publicURL
            );
          }
          if (theMarkup.includes("__REPLACE_WITH_LOCALBUSINESS_IMAGE__")) {
            finalMarkup = theMarkup.replaceAll(
              "__REPLACE_WITH_LOCALBUSINESS_IMAGE__",
              process.env.GATSBY_SITE_URL + localBusinessImage.localFile.publicURL
            );
          }
          if (theMarkup.includes("__REPLACE_WITH_PRODUCT_IMAGE__")) {
            finalMarkup = theMarkup.replaceAll(
              "__REPLACE_WITH_PRODUCT_IMAGE__",
              process.env.GATSBY_SITE_URL + productImage.localFile.publicURL
            );
          }
          // console.log(finalMarkup)
          return <script type="application/ld+json">{finalMarkup}</script>;
        })}
      </Helmet>

      <Helmet>
        <meta
          name="google-site-verification"
          content="sjzCdtAkhHmAQ1nDok8ST8F2SAkDD3qoXuchvK9z0G8"
        />
      </Helmet>

      <main>{children}</main>
      {isContact || isHome ? "" : <Footer />}
    </div>
  );
};

export default Layout;
