import React  from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

export default (props) => {
  const { wpMenu, careers } = useStaticQuery(graphql`
    {
      wpMenu(slug: {eq: "footer-menu"}) {
        menuItems {
          nodes {
            id
            path
            label
            target
            cssClasses
            childItems {
              nodes {
                id
                path
                label
              }
            }
            parentId
          }
        }
      }
      careers: allWpCareer{
        nodes{
          title
        }
      }
    }
  `)
  
  
  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <div className="nav">
      <ul className="menu">

            {wpMenu.menuItems.nodes.map((menuItem, i) => {
              if (menuItem.parentId) {
                return null
              }
              
              const path = menuItem?.connectedNode?.node?.path ?? menuItem.path

              return (
                <li className={menuItem.cssClasses}  key={'link_'+menuItem.id}>
                  <Link
                    key={'link_'+menuItem.id}
                    to={menuItem.target === '_blank' ? path : path}
                  > 
                    <span className="text">{menuItem.label}{menuItem.label === 'Careers' ? <sup> {careers.nodes.length}</sup>:''}</span><span className="line"></span>
                  </Link>
                </li>
              )
            })}
        </ul>
      </div>
  ) : null
}

