import React from "react";
import parse from "html-react-parser";
import { Link } from "gatsby";
import "../../sass/components/_promotions_banner.scss";
import { useEffect } from "react";
import { globalHistory } from "@reach/router";

export default function OffersBanner({ text, cta }) {
  const offsetItems = () => {
    if (typeof window !== "undefined") {
      const banner = document.querySelector(".prom-ban");
      const header = document.querySelector("#header");
      const home = document.querySelector("#home");

      const main = document.querySelector("main");
      const contact = document.querySelector(".page-template-contact");

      if (banner) {
        const bannerHeight = banner.offsetHeight;
        header.style.top = `${bannerHeight}px`;

        if (home) {
          main.style.marginTop = "0px";
        } else {
          main.style.marginTop = `${bannerHeight}px`;
        }

        console.log(home);

        if (contact) {
          contact.style.height = `calc((var(--vh, 1vh) * 100) - ${bannerHeight}px)`;
        }
      }
    }
  };

  useEffect(() => {
    offsetItems();

    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        offsetItems();
      }
    });
  });

  return (
    <div className="prom-ban">
      <span className="text">
        {parse(text)}
        <Link className="linkBtn" to={cta.buttonUrl}>
          {cta.buttonText}
        </Link>
      </span>
      {/* <button className="close">Hide</button> */}
    </div>
  );
}
