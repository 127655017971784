import React, { Component, createRef } from "react";
import { Link } from "gatsby";
import Menu from "./Menu";
import MenuMobile from "./Menu-Mobile";
import Logo from "../../assets/logo.inline.svg";
import parse from "html-react-parser";
import { globalHistory } from "@reach/router";
import gsap from "gsap";
import "../../sass/layout/_header.scss";
import { throttle } from "lodash";

class Header extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.header = createRef();
    this.mobMenuRef = createRef();
    // this.menuRef = createRef();
    this.navToggleRef = createRef();

    // console.log('header', this.header.current);
    // console.log('mobMenu', this.mobMenuRef.current);
    // console.log('menu', this.menuRef.current);
    // console.log('navToggle', this.navToggleRef.current);

    this.state = {
      mobNavIsActive: false,
      windowWidth: undefined,
    };
  }

  makeHeaderSmall = () => {
    if (this.state.windowWidth > 1024) {
      gsap.to(this.header.current.querySelector(".wrapper-large"), { y: -40, duration: 0.5 });
      gsap.to(this.header.current.querySelector(".logo"), { y: 4, duration: 0.5 }, "-0.5");
      gsap.to(this.header.current, { paddingLeft: 40, paddingRight: 40, duration: 0.5 }, "-0.5");
    } else {
      gsap.to(this.header.current.querySelector(".logo"), { y: 4, duration: 0.5 });
    }
  };

  makeHeaderLarge = () => {
    if (this.state.windowWidth > 1024) {
      gsap.to(this.header.current.querySelector(".wrapper-large"), { y: 0, duration: 0.5 });
      gsap.to(this.header.current.querySelector(".logo"), { y: 0, duration: 0.5 }, "-0.5");
      gsap.to(this.header.current, { paddingLeft: 80, paddingRight: 80, duration: 0.25 }, "-0.5");
    } else {
      gsap.to(this.header.current.querySelector(".logo"), { y: 0, duration: 0.5 });
    }
  };

  closeMobNav = () => {
    this.mobMenuRef.current.classList.remove("active");
    this.header.current.classList.remove("mob-menu-active");

    if (typeof window !== undefined) {
      const menus = Array.from(document.querySelectorAll(".menu"));
      const subMenus = Array.from(document.querySelectorAll(".sub-menu"));

      menus.map((menu) => menu.classList.remove("expand"));
      subMenus.map((menu) => menu.classList.remove("expand-sub-menu"));
      subMenus.map((menu) => menu.classList.remove("show"));
    }

    setTimeout(
      function () {
        if (typeof window !== "undefined") {
          document.querySelector("body").style.overflow = "visible";
        }
        this.mobMenuRef.current.classList.remove("trans_out");
        this.mobMenuRef.current.classList.add("trans_in");
        this.mobMenuRef.current.classList.remove("active");
        this.navToggleRef.current.classList.remove("active");
        this.header.current.classList.remove("white_override");

        if (window.scrollY > 50) {
          this.header.current.classList.add("toggle_header");
        }

        this.setState({
          mobNavIsActive: false,
        });
        this.mobMenuRef.current.querySelector(".bg").classList.remove("fill-available-height");
      }.bind(this),
      600
    );
  };

  handleSetCookie = (e) => {
    e.target.closest(".cookie_popup").classList.add("hide");
    localStorage.setItem("edge_allowCookies", true);
  };

  openMobNav = () => {
    if (typeof window !== "undefined") {
      const body = document.querySelector("body");
      const height = window.innerHeight;
      const bg = this.mobMenuRef.current.querySelector(".bg");

      bg.style.height = height + "px";
      this.mobMenuRef.current.style.height = height + "px";

      body.style.overflow = "hidden";
    }

    this.mobMenuRef.current.classList.add("active");
    this.navToggleRef.current.classList.add("active");
    this.header.current.classList.add("mob-menu-active");
    if (this.header.current.classList.contains("toggle_header")) {
      this.header.current.classList.remove("toggle_header");
    }
    this.header.current.classList.add("white_override");

    setTimeout(
      function () {
        this.mobMenuRef.current.classList.remove("trans_in");
        this.mobMenuRef.current.classList.add("trans_out");
        this.setState({
          mobNavIsActive: true,
        });
      }.bind(this),
      1000
    );
  };

  handleScroll = (e) => {
    // console.log(e.target)
    const scroll = e.target.scrollTop;
    let scrollThreshold;

    if (window.location.pathname === "/") {
      scrollThreshold = (window.innerHeight / 100) * 50;
    } else {
      scrollThreshold = 50;
    }

    if (scroll > scrollThreshold && !this.header.current.classList.contains("mob-menu-active")) {
      this.header.current.classList.add("toggle_header");
      this.makeHeaderSmall();
    } else {
      this.header.current.classList.remove("toggle_header");
      this.makeHeaderLarge();
    }
  };

  fakeHoverStart = (e) => {
    const target = e.target.closest(".hover");
    target.classList.add("js_hover");

    // if(e.type === 'mouseenter'){
    //   // console.log('in', 'mouseenter');

    // }
    // if(e.type === 'touchstart'){
    //   console.log('in', 'touchstart')
    // }
  };

  fakeHoverEnd = (e) => {
    const target = e.target.closest(".hover");
    setTimeout(
      function () {
        target.classList.remove("js_hover");
      }.bind(this),
      1
    );
    // if(e.type === 'mouseleave'){
    //   console.log( 'out', 'mouseleave');
    // }
    // if(e.type === 'touchend'){
    //   console.log('out', 'touchend');
    // }
    // if(e.type === 'touchcancel'){
    //   console.log('out', 'touchcancel');
    // }
  };

  setWidth = (width) => {
    this.setState({
      windowWidth: width,
    });
  };

  componentDidMount() {
    if (typeof document !== "undefined") {
      const hoverableElements = document.querySelectorAll(".hover");

      hoverableElements.forEach((element) => {
        element.addEventListener("touchstart", this.fakeHoverStart.bind(this), { passive: true });
        element.addEventListener("touchend", this.fakeHoverEnd.bind(this), { passive: true });
        element.addEventListener("touchcancel", this.fakeHoverEnd.bind(this), { passive: true });
        element.addEventListener("mouseenter", this.fakeHoverStart.bind(this), { passive: true });
        element.addEventListener("mouseleave", this.fakeHoverEnd.bind(this), { passive: true });
      });

      this.setWidth(window.innerWidth);
    }

    globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        if (this.mobMenuRef.current.classList.contains("active")) {
          this.closeMobNav();

          const expanded = Array.from(
            this.mobMenuRef.current.querySelectorAll(".menu-item-has-children.expand")
          );
          if (expanded.length > 0) {
            expanded.map((item) => item.classList.remove("expand"));
          }
        }
      }
    });

    if (!this.header.current.classList.contains("show")) {
      this.header.current.classList.add("show");
    }

    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => this.setWidth(window.innerWidth), { passive: true });
      window.addEventListener(
        "resize",
        () => {
          if (window.innerWidth > 1024) {
            this.closeMobNav();
          }
        },
        { passive: true }
      );
      const scroller = document.querySelector("#container-scroller");
      //console.log('this is the scroller', scroller)
      scroller.addEventListener("scroll", throttle(this.handleScroll.bind(this), 200), {
        passive: true,
      });
    }
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", () => this.setWidth(window.innerWidth), {
        passive: true,
      });
      const scroller = document.querySelector("#container-scroller");
      scroller.removeEventListener("scroll", this.handleScroll.bind(this), { passive: true });
    }
  }

  handleColorChange(e) {
    if (typeof window !== "undefined") {
      const bg_color = e.target.closest("a").getAttribute("data-bg-color");
      const body = document.querySelector("body");
    }
  }

  render() {
    console.log("is404??", this.props.is404);
    return (
      <>
        <header id="header" ref={this.header} className="">
          <div className="fakeBefore"></div>
          <div className="wrapper-large">
            {this.props.is404 === true ? (
              <a
                href="https://edge45.co.uk"
                className="hover test-404"
                onClick={(e) => {
                  e.preventDefault();
                  if (typeof window !== "undefined") {
                    window.location = "/";
                  }
                }}
                data-bg-color="bg-orange-grad-landing">
                <Logo />
                <span className="indent_text">Go to homepage</span>
              </a>
            ) : (
              <Link
                onClick={(e) => {
                  this.handleColorChange(e);
                }}
                to="/"
                className="hover"
                data-bg-color="bg-orange-grad-landing">
                <Logo />
                <span className="indent_text">Go to homepage</span>
              </Link>
            )}
            <Menu
              handleColorChange={this.handleColorChange}
              // ref={this.menuRef}
              mobNav={false}
            />

            <div className="mob-nav">
              <a className="phone_mob btn" href={`tel:${this.props.phone}`}>
                <span>Call us now</span>
              </a>
              <div
                ref={this.navToggleRef}
                className="navToggle hover"
                onClick={this.state.mobNavIsActive == true ? this.closeMobNav : this.openMobNav}>
                <div className="navToggle-inner">
                  <div className="line top"></div>
                  <div className="line middle"></div>
                  <div className="line bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div ref={this.mobMenuRef} className="mob-nav-flyin trans_in">
          <div className="bg"></div>
          <MenuMobile
            ref={this.mobMenuInnerRef}
            mobNav={true}
            closeMobNav={this.closeMobNav}
            openMobNav={this.openMobNav}
            handleColorChange={this.handleColorChange}
            mobNavIsActive={this.state.mobNavIsActive}
          />
        </div>
        {/* {typeof window !== "undefined" && localStorage.getItem("edge_allowCookies") === null ? (
          <div className="cookie_popup">
            <span className="text">{parse(this.props.cookieText)}</span>{" "}
            <button className="popupAccept" onClick={(e) => this.handleSetCookie(e)}>
              I understand
            </button>
          </div>
        ) : (
          ""
        )} */}
      </>
    );
  }
}

export default Header;
