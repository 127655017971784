import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";

export default (props) => {
  const { wpMenu, careers } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "header-menu" }) {
        menuItems {
          nodes {
            id
            path
            label
            target
            cssClasses
            childItems {
              nodes {
                id
                path
                label
                cssClasses
                childItems {
                  nodes {
                    id
                    path
                    label
                    childItems {
                      nodes {
                        id
                        path
                        label
                      }
                    }
                  }
                }
              }
            }
            parentId
          }
        }
      }
      careers: allWpCareer {
        nodes {
          title
        }
      }
    }
  `);

  const handleShowSubSubMenu = (e) => {
    // const target = e.target.closest('.fakeItem')
    // if(target.classList.contains('active')){
    //   target.classList.remove('active')
    // }else{
    //   target.classList.add('active')
    // }
  };
  const handleCloseSubSubMenu = (e) => {
    // const targets = Array.from(document.querySelectorAll('.fakeItem'))
    // targets.map(target => {
    //   if(target.classList.contains('active')){
    //     target.classList.remove('active')
    //   }
    // })
  };

  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <div className="nav">
      <ul className="menu">
        {wpMenu.menuItems.nodes.map((menuItem, i) => {
          if (menuItem.parentId) {
            return null;
          }

          const path = menuItem?.connectedNode?.node?.path ?? menuItem.path;
          return (
            <li className={menuItem.cssClasses} key={"link_" + menuItem.id}>
              {menuItem.cssClasses.includes("phone_icon") ? (
                <a key={"link_" + menuItem.id} href={menuItem.path}>
                  <span className="text">{menuItem.label}</span>
                  <span className="line"></span>
                </a>
              ) : (
                <Link
                  key={"link_" + menuItem.id}
                  to={menuItem.target === "_blank" ? path : path}
                  onClick={() => handleCloseSubSubMenu()}>
                  <span className="text">{menuItem.label}</span>
                  <span className="line"></span>
                </Link>
              )}
              {props.mobNav === true ? parse("</div>") : ""}

              <ul className="sub-menu">
                {menuItem.childItems.nodes &&
                  menuItem.childItems.nodes.map((subitem) => (
                    <li
                      key={"sublink_" + subitem.id}
                      className={`${subitem.cssClasses} ${
                        subitem?.childItems?.nodes.length > 0 ? "menu-item-has-children" : ""
                      }`}>
                      {subitem?.childItems?.nodes.length > 0 ? (
                        <ul className="sub-sub-menu">
                          {subitem?.childItems?.nodes.map((subsubitem) => {
                            return (
                              <li
                                className={`${subsubitem.cssClasses} ${
                                  subsubitem?.childItems?.nodes.length > 0
                                    ? "menu-item-has-children"
                                    : ""
                                }`}>
                                <Link
                                  to={subsubitem.path}
                                  key={menuItem.id + "x"}
                                  onClick={() => handleCloseSubSubMenu()}>
                                  <span className="text">{subsubitem.label}</span>
                                  <span className="line"></span>
                                </Link>
                                {subsubitem?.childItems?.nodes.length > 0 ? (
                                  <ul className="sub-sub-sub-menu">
                                    {subsubitem?.childItems?.nodes.map((subsubsubitem) => {
                                      return (
                                        <li
                                          className={`${subsubsubitem.cssClasses} ${
                                            subsubsubitem?.childItems?.nodes.length > 0
                                              ? "menu-item-has-children"
                                              : ""
                                          }`}>
                                          <Link
                                            to={subsubsubitem.path}
                                            key={menuItem.id + "x"}
                                            onClick={() => handleCloseSubSubMenu()}>
                                            <span className="text">{subsubsubitem.label}</span>
                                            <span className="line"></span>
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                      <Link
                        to={subitem.path}
                        key={menuItem.id + "x"}
                        onClick={() => handleCloseSubSubMenu()}>
                        <span className="text">
                          {subitem.label}
                          {subitem.label === "Vacancies" ? <sup> {careers.nodes.length}</sup> : ""}
                        </span>
                        <span className="line"></span>
                      </Link>
                    </li>
                  ))}
              </ul>
            </li>
          );
        })}
        {/* <li>
          <Link to={"/ecommerce-form  "}>
            <span className="text">Ecommerce Form</span>
            <span className="line"></span>
          </Link>
        </li> */}
      </ul>
    </div>
  ) : null;
};
